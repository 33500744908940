<div class="page-reset login-section section">
    <div class="flex-container" [ngSwitch]="view">
        <div *ngSwitchCase="'loading'" class="login-container">
            Loading...
        </div>
        <div *ngSwitchCase="'done'" class="login-container">
            <p>Password changed!</p>

            <p>You will now receive a further email to reset your MFA account</p>
        </div>
        <div *ngSwitchCase="'done2'" class="login-container">
            <p>Password changed!</p>
        </div>
        
        <div *ngSwitchCase="'invalid'" class="login-container">
            Request invalid.
        </div>
        <div *ngSwitchCase="'expired'" class="login-container">
            Request expired.
        </div>
        <div *ngSwitchCase="'valid'" class="login-container">
            <h3 class="header">Reset Password</h3>

            <form action="#" method="post" class="login-form">
                <span class="form-item password">
                    <label for="password">Password</label>
                    <input [(ngModel)]="password" [ngModelOptions]="{ standalone: true}" autocomplete="off"
                        type="password" id="password" name="password" placeholder="Type your password" required>
                    <span *ngIf="isSimple" class="error">Password does not meet requirements!</span>
                    <span *ngIf="isRepeatPassword" class="error">Please use new password.</span>
                </span>

                <span class="form-item password">
                    <label for="confirmpassword">Confirm Password</label>
                    <input [(ngModel)]="confirmPassword" [ngModelOptions]="{ standalone: true}" autocomplete="off"
                        type="password" id="confirmpassword" name="password" placeholder="Confirm your password"
                        required>
                    <span *ngIf="isMismatch" class="error">Password must match!</span>
                </span>

                <span *ngIf="isRepeatPassword" class="tips">
                    <p>The password you entered cannot be used because:</p>
                    <ul>
                        <li><strong>It does not meet our complexity requirements:</strong> Please ensure it includes a
                            mix of uppercase and lowercase letters, numbers, and symbols totalling to 16 characters.</li>
                        <li><strong>It has been used previously:</strong> Please choose a password that you have not
                            used before with our service.</li>
                        <li><strong>It may be unsafe:</strong> It resembles passwords that have been exposed in data
                            breaches, making it easier to guess.</li>
                    </ul>
                    <p>Please choose a new password to enhance your account's security.</p>
                </span>

                <span *ngIf="apErrors.length > 0" class="tips">
                    <p>The password you entered cannot be used because:</p>
                    <ul>
                        <li *ngFor="let e of apErrors">{{e}}</li>
                    </ul>
                    <p>Please choose a new password to enhance your account's security.</p>
                </span>

                <span *ngIf="isSimple" class="tips">Password Requirements:
                    Your password must be at least 16 characters long and include:

                    <ul>
                        <li>At least one uppercase letter (A-Z)</li>
                        <li>At least one lowercase letter (a-z)</li>
                        <li>At least one number (0-9)</li>
                        <li>At least one special character (e.g., @, $, !, %, *, ?, &)</li>

                    </ul>
                </span>
                <button *ngIf="id && valid && !isLoading" type="submit" class="login-button"
                    (click)="submit()">SUBMIT</button>

                <button *ngIf="isLoading" type="submit" class="login-button">Pending...</button>
            </form>
        </div>
    </div>
</div>