import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  public action$ = new BehaviorSubject<string>('')
  public token$ = new BehaviorSubject<string>('')
  public isLoading$ = new BehaviorSubject<boolean>(false)
  public param$ = new BehaviorSubject<any>(null)

  constructor() { }

  public getRecaptchatoken(action: string) {
    this.isLoading$.next(true)
    this.action$.next(action)
  }
}
