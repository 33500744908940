import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HtmlDecoderService } from '../html-decoder.service';
import { Login } from '../login';
import { LoginService } from '../login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-autopad',
  templateUrl: './autopad.component.html',
  styleUrls: ['./autopad.component.scss']
})

export class AutopadComponent extends Login implements OnInit, OnDestroy {

  private sub?: Subscription

  constructor(
    loginService: LoginService,
    htmlDecoder: HtmlDecoderService,
    private http: HttpClient,
    private router: Router
  ) {
    super(loginService, htmlDecoder)
  }

  ngOnInit(): void {
    this.sub = this.loginService.token$.subscribe({
      next: token => {
        if (token) {
          this.login(token)
        }
      }
    })

    if(localStorage.getItem('ap-cc')) {
      this.costCentre = localStorage.getItem('ap-cc') || ''
    }
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe()
    }
  }

  private login(token: string) {

    const model = {
      userName: this.userName,
      password: this.password,
      costCentre: this.costCentre,
      captcha: token,
      totp: '',
      sa: '',
      overwrite: true
    };

    if(!model.userName || !model.password || !model.costCentre) {
      this.loginService.isLoading$.next(false)
      return;
    }

    this.http.post(
      `${environment.autopadBaseUrl}/api/v1/token`,
      model
    ).subscribe({
      next: res => {
        const msg = res as any

        if (msg.IsError === true) {
          alert(msg.message)
          this.loginService.isLoading$.next(false)
        } else {
          this.checkBreach(msg.refreshToken)
        }
      },
      error: err => {
        if (err.status) {
          if(err.status == 417){
            this.router.navigateByUrl(`/otp?req=${err.error}&m=ap`)
            return
          } else if(err.status == 403) {
            this.router.navigateByUrl(`/reset-confirm?req=${err.error}&m=ap`)
            return
          }
        }

        this.loginService.isLoading$.next(false)
        alert('Please contact support for login issues.')
      }
    })
  }

  private checkBreach(refreshToken: string) {
    this.http.post(
      `${environment.apiBaseUrl}/v1/Authenticate/hibp`,
      `"${btoa(this.password)}"`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
    ).subscribe({
      next: breached => {
        if (breached === true) {
          alert('We advise that you change your password immediately.')
        }
        localStorage.setItem('ap-cc', this.costCentre)
        
        this.goAutoPad(refreshToken)
      }
    })
  }

}
