import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HtmlDecoderService {
  private element: HTMLElement;

  constructor() {
    this.element = document.createElement('div');
  }

  decodeHTMLEntities(str: string): string {
    if (str && typeof str === 'string') {
      // strip script/html tags
      str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
      str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
      this.element.innerHTML = str;
      str = this.element.textContent || '';
      this.element.textContent = '';
    }

    return str;
  }
}
