<div class="page-reset login-section section">
    <div class="flex-container" [ngSwitch]="view">
        <div *ngSwitchCase="'loading'" class="login-container">
            Loading...
        </div>

        <div *ngSwitchCase="'invalid'" class="login-container">
            Request expired.
        </div>
        <div *ngSwitchCase="'valid'" class="login-container">
            <h3 class="header">MFA Setup</h3>
            <span>Warning! Due to security concerns, we can only show this code to you once.</span>

            <div class="qr" *ngIf="(data?.qrData || '').startsWith('http'); else base">
                <ngx-qrcode [value]="getApQr() || ''">
                </ngx-qrcode>
            </div>
            <ng-template #base>
                <img [src]="data?.qrData">
            </ng-template>

            <div class="code">
                Please manually enter the code below if you have difficulties scanning the QR Code
                <p>
                    {{ data?. code }}
                </p>

                You can use any Authenticator of your choice (Google Authenticator / Microsoft Authenticator)

                <br />
                <strong>Once scanned and setup, you may close this page and login.</strong>
            </div>
        </div>
    </div>
</div>