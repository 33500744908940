import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-migrate-user',
  templateUrl: './migrate-user.component.html',
  styleUrls: ['./migrate-user.component.scss']
})
export class MigrateUserComponent implements OnInit, OnDestroy {

  public id?: string;
  public password?: string;
  public confirmPassword?: string;
  public isRepeatPassword = false;
  public isLoading = false;
  public view = 'loading';
  public module = '';
  public apErrors = []
  public errorMessage = ''

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(p => {
      this.id = p['qry']
      this.module = p['m']

      if (this.module == 'cn') {
        this.getCnStatus();
      }
    })
  }

  ngOnDestroy(): void {
  }

  private getCnStatus() {
    this.view = 'loading';

    this.http.get(
      `${environment.connectUrl}/api/authenticate/migrate?id=${this.id}`,
    ).subscribe({
      next: res => {
        this.view = 'valid'
      },
      error: err => {
        if (err.status && err.status == 400) {
          this.errorMessage = err.error.message
          this.view = 'invalid'
          return
        }

        alert('There is an error in this request!')
      }
    })
  }



  public get isMismatch() {
    if (this.password && this.confirmPassword) {
      return this.password != this.confirmPassword
    }

    return false
  }

  public get isSimple() {
    if (this.password) {
      // Regular expression to check if password is strong
      const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{16,}$/

      // Test the password against the regular expression
      return !strongPasswordRegex.test(this.password);
    }

    return false
  }

  public get valid() {

    if (this.password && this.confirmPassword) {
      if (!this.isMismatch && !this.isSimple) {
        return true
      }
    }
    return false
  }

  public submit() {
    if (this.module == 'cn') {
      this.cnSubmit();
    }
  }

  private cnSubmit() {
    this.isLoading = true
    this.isRepeatPassword = false

    this.http.post(
      `${environment.connectUrl}/api/authenticate/migrate?id=${this.id}`,
      {
        userName: 'connect',
        password: this.confirmPassword,
        reCaptcha: ''
      },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
    ).subscribe({
      next: res => {
        this.view = 'done'
        this.isLoading = false
      },
      error: err => {

        this.isLoading = false

        alert('Sorry! there is an issue with the updating the password.')
      }
    })
  }


}
