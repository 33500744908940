import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HtmlDecoderService } from '../html-decoder.service';
import { Login } from '../login';
import { LoginService } from '../login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sfm',
  templateUrl: './sfm.component.html',
  styleUrls: ['./sfm.component.scss']
})
export class SfmComponent extends Login implements OnInit, OnDestroy {

  private sub?: Subscription

  constructor(
    loginService: LoginService,
    htmlDecoder: HtmlDecoderService,
    private http: HttpClient,
    private router: Router
  ) {
    super(loginService, htmlDecoder)
  }

  ngOnInit(): void {
    this.sub = this.loginService.token$.subscribe({
      next: token => {
        if (token) {
          this.login(token)
        }
      }
    })

    if (localStorage.getItem('sfm-cc')) {
      this.costCentre = localStorage.getItem('sfm-cc') || ''
    }
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe()
    }
  }

  private login(token: string) {

    localStorage.setItem('sfm-cc', this.costCentre)

    var postData = {
      username: this.userName,
      password: this.password,
      costcentre: this.costCentre,
      reCaptcha: token,
      module: 1
    }

    if(!postData.username || !postData.password || !postData.costcentre) {
      this.loginService.isLoading$.next(false)
      return
    }

    this.http.post(
      `${environment.apiBaseUrl}/v1/Authenticate/login`,
      postData
    ).subscribe({
      next: res => {
        const msg = res as any

        if (msg.IsError === true) {
          alert(msg.message)
          this.loginService.isLoading$.next(false)
        } else {
          this.checkBreach(msg)
        }
      },
      error: err => {
        if (err.status && err.status == 401) {
          if (err.error && err.error.type == 'otp') {
            this.router.navigateByUrl(`/otp?req=${err.error.msg}&m=sfm`)
            return
          }
        }

        this.loginService.isLoading$.next(false)
        alert('Please contact support for login issues.')
      }
    })
  }

  private checkBreach(resp: any) {
    this.http.post(
      `${environment.apiBaseUrl}/v1/Authenticate/hibp`,
      `"${btoa(this.password)}"`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
    ).subscribe({
      next: breached => {
        if (breached === true) {
          alert('We advise that you change your password immediately.')
        }

        this.goShopfloorMobile(resp)
      }
    })
  }

}
