<div class="page-reset login-section section">
    <div class="flex-container" [ngSwitch]="view">
        <div *ngSwitchCase="'loading'" class="login-container">
            Loading...
        </div>
        <div *ngSwitchCase="'done'" class="login-container">
            <h3>Update complete!</h3>

            <p>Please login with your new credentials.</p>
        </div>
        <div *ngSwitchCase="'invalid'" class="login-container">
            {{ errorMessage }}
        </div>
        <div *ngSwitchCase="'valid'" class="login-container">
            <h3 class="header">Update Password</h3>

            <form action="#" method="post" class="login-form">
                <span class="form-item password">
                    <label for="password">Password</label>
                    <input [(ngModel)]="password" [ngModelOptions]="{ standalone: true}" autocomplete="off"
                        type="password" id="password" name="password" placeholder="Type your password" required>
                    <span *ngIf="isSimple" class="error">Password does not meet requirements!</span>
                </span>

                <span class="form-item password">
                    <label for="confirmpassword">Confirm Password</label>
                    <input [(ngModel)]="confirmPassword" [ngModelOptions]="{ standalone: true}" autocomplete="off"
                        type="password" id="confirmpassword" name="password" placeholder="Confirm your password"
                        required>
                    <span *ngIf="isMismatch" class="error">Password must match!</span>
                </span>

                <span *ngIf="isSimple" class="tips">Password Requirements:
                    Your password must be at least 16 characters long and include:

                    <ul>
                        <li>At least one uppercase letter (A-Z)</li>
                        <li>At least one lowercase letter (a-z)</li>
                        <li>At least one number (0-9)</li>
                        <li>At least one special character (e.g., @, $, !, %, *, ?, &)</li>

                    </ul>
                </span>
                <button *ngIf="id && valid && !isLoading" type="submit" class="login-button"
                    (click)="submit()">SUBMIT</button>

                <button *ngIf="isLoading" type="submit" class="login-button">Pending...</button>
            </form>
        </div>
    </div>
</div>