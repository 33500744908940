<div class="page-reset login-section section">
    <div class="flex-container" [ngSwitch]="view">

        <div *ngSwitchCase="'sent'" class="login-container">
            <p>Request confirmed!</p>

            <p>You will now receive a further email to reset your password</p>
        </div>
        <div *ngSwitchCase="'failed'" class="login-container">
            <p>
                Sorry, your email does not match our records. Please contact the support for more information.
            </p>
        </div>
        <div *ngSwitchCase="'expired'" class="login-container">
            <p>
                Sorry, request expired. Please contact the support for more information.
            </p>
        </div>
        <div *ngSwitchCase="'start'" class="login-container">
            <h3 class="header">Reset Password</h3>
            <p>Please enter your E-mail address as confirmation.
            <br />Your E-mail address must match your user account's E-mail address.</p>
            <form action="#" method="post" class="login-form">
                <span class="form-item username">
                    <label for="email">E-mail</label>
                    <input [(ngModel)]="email" [ngModelOptions]="{ standalone: true}" autocomplete="off" type="text"
                        id="email" name="email" placeholder="Type your E-mail address" required>

                </span>
                <button *ngIf="id && email && !isLoading" type="submit" class="login-button"
                    (click)="submit()">SUBMIT</button>

                <button *ngIf="isLoading" type="submit" class="login-button">Pending...</button>
            </form>
        </div>
    </div>
</div>