import { Component } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { LoginService } from './login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'BodyNet | Login';

  constructor(
    public recaptchaV3Service: ReCaptchaV3Service,
    private loginService: LoginService
  ) {
    this.loginService.action$.subscribe({
      next: action => {
        if (action) {
          this.getCode(action);
        }
      }
    })
  }

  public getCode(x: string) {
    this.recaptchaV3Service.execute(x)
      .subscribe({
        next: token => {
          this.loginService.token$.next(token)
        },
        complete: () => {

        },
        error: err => {
          alert('ReCaptcha Error!')
        }
      })
  }
}
