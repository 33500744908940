import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BmsComponent } from './bms/bms.component';
import { AutopadComponent } from './autopad/autopad.component';
import { SfComponent } from './sf/sf.component';
import { SfmComponent } from './sfm/sfm.component';
import { ConnectComponent } from './connect/connect.component';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ResetComponent } from './reset/reset.component';
import { OtpComponent } from './otp/otp.component';
import { MfaSetupComponent } from './mfa-setup/mfa-setup.component';
import { ResetConfirmComponent } from './reset-confirm/reset-confirm.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { MigrateUserComponent } from './migrate-user/migrate-user.component';

@NgModule({
  declarations: [
    AppComponent,
    BmsComponent,
    AutopadComponent,
    SfComponent,
    SfmComponent,
    ConnectComponent,
    ResetComponent,
    OtpComponent,
    MfaSetupComponent,
    ResetConfirmComponent,
    MigrateUserComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    NgxQRCodeModule,

    RecaptchaV3Module,
    HttpClientModule
  ],
  providers: [{
    provide: RECAPTCHA_V3_SITE_KEY,
    useValue: environment.reCaptchaSiteKey
    //'6LcAzzInAAAAAIRSmiX-HAX9vdL16q0UXmn_aCdZ'
  }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
