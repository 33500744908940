import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mfa-setup',
  templateUrl: './mfa-setup.component.html',
  styleUrls: ['./mfa-setup.component.scss']
})
export class MfaSetupComponent implements OnInit, OnDestroy {

  public id?: string;
  public data?: {
    qrData: string,
    code: string
  };
  public isLoading = false;
  public view = 'loading';

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(p => {
      this.id = p['qry']

      if (this.route.snapshot.data['isAutoPad'] == true) {
        this.getApSetup();
      } else {
        this.getSetup();
      }
    })
  }

  ngOnDestroy(): void {
  }

  extractChlParameter(url: string): string | null {
    try {
      // Create a new URL object
      const parsedUrl = new URL(url);

      // Use URLSearchParams to get the 'chl' parameter
      const params = new URLSearchParams(parsedUrl.search);
      return params.get('chl');
    } catch (error) {
      console.error('Invalid URL', error);
      return null;
    }
  }

  public getApQr() {
    if(this.data) {
      if(this.data.qrData) {
        return this.extractChlParameter(this.data.qrData)
      }
    }

    return ''
  }

  private getSetup() {
    this.view = 'loading';

    this.http.get(
      `${environment.apiBaseUrl}/v1/Authenticate/mfa-setup?id=${this.id}`,
    ).subscribe({
      next: res => {
        this.view = 'valid'
        this.data = res as any
      }, error: err => {
        this.view = 'invalid'
        if (err.status == 400) {

        }
      }
    })
  }

  private getApSetup() {
    this.view = 'loading';

    this.http.get(
      `${environment.autopadBaseUrl}/api-v3/auth/show-mfa-setup?id=${this.id}`,
      //`https://localhost:44318/auth/show-mfa-setup?id=${this.id}`,
           
    ).subscribe({
      next: res => {
        this.view = 'valid'
        this.data = res as any
      }, error: err => {
        this.view = 'invalid'
        if (err.status == 400) {

        }
      }
    })
  }

}
