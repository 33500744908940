import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reset-confirm',
  templateUrl: './reset-confirm.component.html',
  styleUrls: ['./reset-confirm.component.scss']
})
export class ResetConfirmComponent implements OnInit, OnDestroy {

  public id?: string;
  public email?: string;
  public isLoading = false;
  public view =  'start';

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(p => {
      this.id = p['req']
    })
  }

  ngOnDestroy(): void {
  }

  public submit() {
    this.isLoading = true    

    this.http.post(
      `${environment.autopadBaseUrl}/api/v1/token/reset-confirm?id=${this.id}`,
      `"${btoa(this.email || '')}"`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
    ).subscribe({
      next: rs => {
        if (rs === true) {
          this.view = 'sent'
        } else {
          this.view = 'failed';
        }
      },
      error: err=> {
        this.isLoading = false

        if(err.status && err.status == 409) {
          this.view = 'expired'
          return
        }
        
        alert('Sorry! there is an issue with this request!')
        
      },
      complete: ()=> {
        this.isLoading = false
      }
    })
  }
  
}
