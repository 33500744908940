import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutopadComponent } from './autopad/autopad.component';
import { BmsComponent } from './bms/bms.component';
import { ConnectComponent } from './connect/connect.component';
import { SfComponent } from './sf/sf.component';
import { SfmComponent } from './sfm/sfm.component';
import { ResetComponent } from './reset/reset.component';
import { OtpComponent } from './otp/otp.component';
import { MfaSetupComponent } from './mfa-setup/mfa-setup.component';
import { ResetConfirmComponent } from './reset-confirm/reset-confirm.component';
import { MigrateUserComponent } from './migrate-user/migrate-user.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: 'bms',
        pathMatch: 'full'
      },
      {
        path: 'bms',
        component: BmsComponent,
        title: 'BodyNet | BMS'
      },
      {
        path: 'autopad',
        component: AutopadComponent,
        title: 'BodyNet | AutoPad'
      },
      {
        path: 'sf',
        component: SfComponent,
        title: 'BodyNet | ShopFloor Desktop'
      },
      {
        path: 'sfm',
        component: SfmComponent,
        title: 'BodyNet | ShopFloor Mobile'
      },
      {
        path: 'connect',
        component: ConnectComponent,
        title: 'BodyNet | Connect'
      },
      {
        path: 'reset',
        component: ResetComponent,
        title: 'BodyNet | Reset Password'
      },
      {
        path: 'reset-confirm',
        component: ResetConfirmComponent,
        title: 'BodyNet | Confirm Reset Password'
      },
      {
        path: 'otp',
        component: OtpComponent,
        title: 'BodyNet | MFA Authentication'
      },
      {
        path: 'mfa-setup',
        component: MfaSetupComponent,
        title: 'BodyNet | MFA Setup'
      },
      {
        path: 'mfa-setup-2',
        component: MfaSetupComponent,
        title: 'BodyNet | MFA Setup | AutoPad',
        data: { isAutoPad : true }
      },
      {
        path: 'migrate',
        component: MigrateUserComponent,
        title: 'BodyNet | Migrate',
      }
    ]
  }]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
