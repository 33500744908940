import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Login } from '../login';
import { LoginService } from '../login.service';
import { HtmlDecoderService } from '../html-decoder.service';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent extends Login implements OnInit {

  userInput?: string
  isLoading = false
  req?: string
  msg = 'Please enter your one time password from your authentication device'
  module = ''

  constructor(
    loginService: LoginService,
    htmlDecoder: HtmlDecoderService,
    private route: ActivatedRoute,
    private http: HttpClient
  ) {
    super(loginService, htmlDecoder)
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(param => {
      this.req = param['req']
      this.module = param['m']
    })
  }

  override submit() {
    this.isLoading = true

    if(this.module == 'ap') {
      this.apLogin()
    } else {
      this.bmsLogin()
    }
  }

  apLogin() {
    var postData = {
      refId: this.req,
      code: this.userInput
    }

    this.http.post(
      `${environment.autopadBaseUrl}/api/v1/token/otp`,
      postData
    ).subscribe({
      next: res => {
        const resp = res as any
        this.goAutoPad(resp.refreshToken)
      },
      error: err => {
        this.isLoading = false
        if (err.status && err.status == 401) {
          this.msg = err.error
        }
      }
    })
  }

  bmsLogin() {
    var postData = {
      refId: this.req,
      otp: this.userInput
    }

    this.http.post(
      `${environment.apiBaseUrl}/v1/Authenticate/otp`,
      postData
    ).subscribe({
      next: res => {
        const resp = res as any

        switch (this.module) {
          case 'sf':
            this.goShopfloor(resp)
            break
          case 'sfm':
            this.goShopfloorMobile(resp)
            break
          case 'bms':
            this.goBms(resp)
            break
        }
      },
      error: err => {
        this.isLoading = false
        if (err.status && err.status == 401) {
          this.msg = err.error
        }
      }
    })
  }
}
