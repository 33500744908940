import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HtmlDecoderService } from '../html-decoder.service';
import { Login } from '../login';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-connect',
  templateUrl: './connect.component.html',
  styleUrls: ['./connect.component.scss']
})
export class ConnectComponent extends Login implements OnInit, OnDestroy {

  private sub?: Subscription
  public view = 'start'

  constructor(
    loginService: LoginService,
    htmlDecoder: HtmlDecoderService,
    private http: HttpClient
  ) {
    super(loginService, htmlDecoder)
  }

  ngOnInit(): void {
    this.sub = this.loginService.token$.subscribe({
      next: token => {
        if (token) {
          this.login(token)
        }
      }
    })
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe()
    }
  }

  private goAuth0() {
    const domain = 'bodynetuk.eu.auth0.com';
    const clientId = 'Nxz07zKSgyUCVbsWgKLMBzX2hYFzgYcG';
    const redirectUri = encodeURIComponent(`${environment.connectUrl}/token`);
    const responseType = 'token id_token';
    const scope = 'openid profile email';
    const nonce = this.generateNonce();

    const authUrl = `https://${domain}/authorize?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&nonce=${nonce}`;
    window.location.href = authUrl;
  }

  generateNonce(length = 16) {
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let nonce = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      nonce += charset[randomIndex];
    }
    return nonce;
  }

  private login(token: string) {
    const formData = {
      userName: this.userName,
      password: this.userName,// don't matter      
      reCaptcha: token
    }

    if (!formData.userName || !formData.password) {
      this.loginService.isLoading$.next(false)
      return;
    }

    this.http.post(
      `${environment.connectUrl}/api/Authenticate/verify`,
      formData,
      { observe: 'response' } // Observe the full response
    ).subscribe({
      next: (res: HttpResponse<any>) => {
        if (res.status === 202) {
          // Handle status 202
          this.view = 'reset'
        } else {
          // Handle other statuses
          this.goAuth0();
        }
      },
      error: err => {
        this.loginService.isLoading$.next(false);

        if (err && err.status && err.status == 409) {
          this.view = 'invalid'
          return;
        }

        alert('Please contact support for login issues.');
      }
    });
  }

  private checkBreach(token: string) {
    this.http.post(
      `${environment.apiBaseUrl}/v1/Authenticate/hibp`,
      `"${btoa(this.password)}"`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
    ).subscribe({
      next: breached => {
        if (breached === true) {
          alert('We advise that you change your password immediately.')
        }

        window.location.href = `${environment.connectUrl}login/token?t=${token}`
      }
    })
  }

}
