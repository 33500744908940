<header class="header">

  <div class="header-top section">
    <div class="flex-container">
      <div class="contact-info">
        <a href="tel:+441274299001">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
              d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
          </svg>
          <span>+44 (0)1274 299001</span>
        </a>
        <a href="mailto:sales@bodynet.co.uk">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
              d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
          </svg>
          <span>sales@bodynet.co.uk</span>
        </a>
      </div>
      <div class="header-links">
        <a href="https://www.linkedin.com/company/bodynet/" target="_blank" class="linkedin">
          <svg class="e-font-icon-svg e-fab-linkedin-in" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z">
            </path>
          </svg>
        </a>
        <a href="https://bodynet.co.uk" target="_blank" class="nav-link">Main Bodynet Website</a>
      </div>
    </div>
  </div>

  <div class="header-logo section">
    <div class="flex-container">
      <a href="/" class="logo">
        <img src="./assets/img/bodynet-logo.png" alt="Bodynet Logo">
      </a>
    </div>
  </div>

</header>

<main>
  <div class="product-links section">
    <div class="flex-container">
      <h1>Login Below</h1>
      <div class="products-wrapper">
        <a class="bodynet-bms product-link" routerLinkActive="active" routerLink="bms">
          <img src="./assets/img/bodynet-bms-logo-wo.png" alt="Bodynet BMS">
        </a>
        <a class="autopad product-link" routerLinkActive="active" routerLink="autopad">
          <img src="./assets/img/autopad-logo-wo.png" alt="AutoPad">
        </a>
        <a class="shopfloor product-link" routerLinkActive="active" routerLink="sf">
          <img src="./assets/img/shopfloor-desktop-logo-wo.png" alt="Shopfloor">
        </a>
        <a class="shopfloor-mobile product-link" routerLinkActive="active" routerLink="sfm">
          <img src="./assets/img/shopfloor-mobile-logo-wo.png" alt="Shopfloor Mobile">
        </a>
        <a class="connect product-link" routerLinkActive="active" routerLink="connect">
          <img src="./assets/img/connect-logo-wo.png" alt="Connect">
        </a>
      </div>
    </div>
  </div>

  <router-outlet></router-outlet>

</main>

<footer class="footer">

  <div class="footer-content section">

    <div class="flex-container">

      <img src="./assets/img/bodynet-logo.png" alt="Bodynet Logo" class="footer-logo">

      <div class="company-info">

        <div class="company-address">
          <strong>Bodynet Ltd</strong><br>
          Kevin Conway House<br>
          Longbow Close<br>
          Bradley Business Park<br>
          Huddersfield<br>
          HD2 1GQ
        </div>

        <div class="contact-info">
          <strong>Contact Us</strong><br>
          Support: 01274 214250<br>
          Sales: 01274 299001
        </div>

      </div>

      <div class="footer-bottom">
        &copy; Bodynet UK, All Rights Reserved
      </div>

    </div>

  </div>

</footer>