<div class="page-connect login-section section">
    <div class="flex-container">
        <div class="login-container">

            <!-- <img src="./assets/img/connect-logo.png" alt="Connect MI" class="login-logo"> -->

            <form action="#" method="post" class="login-form">
                <span class="form-item password">
                    <label for="username">OTP Required</label>
                    <input [(ngModel)]="userInput" [ngModelOptions]="{ standalone: true}" autocomplete="off" type="text"
                        id="otp" name="otp" placeholder="Type your OTP" required>
                </span>
                <span *ngIf="msg">{{msg}}</span>
                <button *ngIf="!isLoading; else loading" type="submit" class="login-button"
                    (click)="submit()">SUBMIT</button>
                <ng-template #loading>
                    <button type="submit" class="login-button">Pending...</button>
                </ng-template>
            </form>
        </div>
    </div>
</div>