import { environment } from "src/environments/environment"
import { HtmlDecoderService } from "./html-decoder.service"
import { LoginService } from "./login.service"

export abstract class Login {
    public userName: string = ''
    public password: string = ''
    public costCentre: string = ''

    constructor(
        protected loginService: LoginService,
        protected htmlDecoder: HtmlDecoderService
    ) { }

    public submit(action: string, checkCc: boolean = true, param?: number) {

        this.loginService.param$.next(param)

        if (checkCc) {
            if (!this.userName || !this.password || !this.costCentre) {
                // please replace with a nice message popup
                alert("Please provide username, password and costcentre to login.")
                return;
            }

        }
        else if (!this.userName || (!this.password && action != 'connect')) {
            alert('Please provide username and password')
            return
        }

        this.loginService.getRecaptchatoken(action)
    }


    public decode(url: string): string {
        return this.htmlDecoder.decodeHTMLEntities(url)
    }

    public goShopfloor(resp: any) {
        localStorage.setItem('token', resp.token)
        localStorage.setItem('refreshToken', resp.refreshToken)

        window.location.href = `${environment.sfBaseUrl}/Account.aspx/signon?token=${resp.refreshToken}`
    }

    public goShopfloorMobile(resp: any) {
        localStorage.setItem('token', resp.token)
        localStorage.setItem('refreshToken', resp.refreshToken)
        window.location.href = '/shopfloormobile/tabbar.html'
    }

    public goBms(resp: any) {
        localStorage.setItem('token', resp.token)
        localStorage.setItem('refreshToken', resp.refreshToken)
        window.location.href = `${environment.bmsBaseUrl}/account/mgmtsignon?param=${resp.refreshToken}`
    }

    public goAutoPad(refreshToken: any) {
        const link = `${environment.autopadBaseUrl}/login/token?t=${refreshToken}&isAdmin=${this.loginService.param$.value}`;

        window.location.href = link
    }
}
