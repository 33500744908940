import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit, OnDestroy {

  public id?: string;
  public password?: string;
  public confirmPassword?: string;
  public isRepeatPassword = false;
  public isLoading = false;
  public view = 'loading';
  public module = '';
  public apErrors = []

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(p => {
      this.id = p['qry']
      this.module = p['m']

      if (this.module == 'ap') {
        this.getApStatus();
      } else {
        this.getStatus();
      }
    })
  }

  ngOnDestroy(): void {
  }

  private getApStatus() {
    this.view = 'loading';

    this.http.get(
      `${environment.autopadBaseUrl}/api/v1/token/reset-status?id=${this.id}`,
    ).subscribe({
      next: res => {
        if (res == true) {
          this.view = 'valid'
        } else {
          this.view = 'invalid'
        }
      },
      error: err => {
        if (err.error && err.error == 417) {
          this.view = 'expired'
          return
        }

        alert('There is an error in this request!')
      }
    })
  }

  private getStatus() {
    this.view = 'loading';

    this.http.get(
      `${environment.apiBaseUrl}/v1/Authenticate/request-status?id=${this.id}`,
    ).subscribe({
      next: res => {
        if (res == true) {
          this.view = 'valid'
        } else {
          this.view = 'invalid'
        }
      },
      error: err => {
        alert('There is an error in this request!')
      }
    })
  }


  public get isMismatch() {
    if (this.password && this.confirmPassword) {
      return this.password != this.confirmPassword
    }

    return false
  }

  public get isSimple() {
    if (this.password) {
      // Regular expression to check if password is strong
      const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{16,}$/

      // Test the password against the regular expression
      return !strongPasswordRegex.test(this.password);
    }

    return false
  }

  public get valid() {

    if (this.password && this.confirmPassword) {
      if (!this.isMismatch && !this.isSimple) {
        return true
      }
    }
    return false
  }

  public submit() {
    if (this.module == 'ap') {
      this.apSubmit();
    } else {
      this.bnSubmit();
    }
  }

  private apSubmit() {
    this.isLoading = true
    this.isRepeatPassword = false
    this.apErrors = []

    this.http.post(
      `${environment.autopadBaseUrl}/api/v1/token/set-password?id=${this.id}`,
      {
        newPassword: this.password,
        confirmPassword: this.confirmPassword
      },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
    ).subscribe({
      next: res => {
    
        if ((res as any).id) {
          this.http.get(
            `${environment.autopadBaseUrl}/api-v3/auth/request-mfa-reset?id=${(res as any).id}`,
           //'https://localhost:44318/auth/request-mfa-reset?id='+ (res as any).id,
            {
              headers: new HttpHeaders({
                'Content-Type': 'application/json'
              })
            }
          ).subscribe({
            next: done => {
              this.view = 'done' // same with bn flow
              this.isLoading = false
            }, 
            error: err => { alert('There is an issue requesting for MFA setup!') }
          })

          return
        } else {
          this.view = 'done2'
          this.isLoading = false
        }
      },
      error: err => {
       
        this.isLoading = false

        if (err.status && err.status == 403) {
          this.apErrors = err.error
          return
        }

        alert('Sorry! there is an issue with the updating the password.')
      }
    })
  }

  private bnSubmit() {
    this.isLoading = true
    this.isRepeatPassword = false

    this.http.post(
      `${environment.apiBaseUrl}/v1/Authenticate/check-password?qry=${this.id}`,
      `"${btoa(this.password || '')}"`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
    ).subscribe({
      next: isRepeat => {
        if (isRepeat === true) {
          this.isRepeatPassword = true
          this.isLoading = false
        } else {
          this.changePassword();
        }
      },
      error: err => {
        alert('Sorry! there is an issue with the updating the password.')
      }
    })
  }

  private changePassword() {
    this.http.post(
      `${environment.apiBaseUrl}/v1/Authenticate/change-password?qry=${this.id}`,
      `"${btoa(this.password || '')}"`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
    ).subscribe({
      next: res => {
        this.view = 'done'
      },
      error: err => {
        if (err.status && err.status == 400) {
          this.isRepeatPassword = true
          this.isLoading = false
        } else {
          alert('Password change failed! Please try again later.')
        }

      }
    })
  }

}
