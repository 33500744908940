<div class="page-connect login-section section">
    <div class="flex-container" [ngSwitch]="view">
        <div *ngSwitchCase="'invalid'" class="login-container">
            <h3>Your password has expired</h3>
            <span>Please contact support for login issues. <br />Invalid E-mail address.</span>
        </div>
        <div *ngSwitchCase="'reset'" class="login-container">
            <h3>Your password has expired</h3>
            <span>Please check your email for reset instructions.</span>
        </div>
        <div *ngSwitchCase="'start'" class="login-container">

            <img src="./assets/img/connect-logo.png" alt="Connect MI" class="login-logo">

            <form action="#" method="post" class="login-form">
                <span class="form-item username">
                    <label for="username">Username</label>
                    <input [(ngModel)]="userName" [ngModelOptions]="{ standalone: true}" autocomplete="off" type="text"
                        id="username" name="username" placeholder="Type your username" required>
                </span>
                <button *ngIf="!(loginService.isLoading$ | async); else loading" type="submit" class="login-button"
                    (click)="submit('connect', false)">LOGIN</button>
                <ng-template #loading>
                    <button type="submit" class="login-button">Pending...</button>
                </ng-template>
            </form>
        </div>
    </div>
</div>