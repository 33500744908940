<div class="page-shopfloor-mobile login-section section">
    <div class="flex-container">
        <div class="login-container">

            <img src="./assets/img/shopfloor-mobile-logo.png" alt="Shopfloor Mobile" class="login-logo">

            <form action="#" method="post" class="login-form">
                <span class="form-item username">
                    <label for="username">Username</label>
                    <input [(ngModel)]="userName" [ngModelOptions]="{ standalone: true}" autocomplete="off" type="text"
                    id="username" name="username" placeholder="Type your username" required>
                </span>
                <span class="form-item password">
                    <label for="password">Password</label>
                    <a class="forgot-password" href="mailto:support@bodynet.co.uk?subject=%5BShopfloorMobile%5D%20Password%20Reset%20Request&body=Please%20can%20you%20contact%20me%20urgently%20to%20reset%20my%20password.%20%0A%0AMy%20username%20is%20%5BEnter%20username%20here%5D.%20%0A%0AMy%20costcentre%20is%20%5BEnter%20costcentre%20code%20here%5D.%0A%0A">Forgot Password?</a>
                    <input [(ngModel)]="password" [ngModelOptions]="{ standalone: true}" autocomplete="off"
                        type="password" id="password" name="password" placeholder="Type your password" required>
                </span>
                <span class="form-item cost-centre">
                    <label for="costCentre">Cost Centre</label>
                    <input [(ngModel)]="costCentre" [ngModelOptions]="{ standalone: true}" autocomplete="off"
                        type="text" id="costCentre" name="costCentre" placeholder="Type your cost centre" required>
                </span>
                <button *ngIf="!(loginService.isLoading$ | async); else loading" type="submit" class="login-button" (click)="submit('sfm')">LOGIN</button>
                <ng-template #loading>
                    <button type="submit" class="login-button">Pending...</button>
                </ng-template>
            </form>

        </div>
    </div>
</div>